import { Fragment, useContext, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  ArrowRightOnRectangleIcon,
  Bars3BottomLeftIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
  UserIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import Logo from 'assets/icons/logo.png';
import { auth } from 'firebaseConfig';
import { signOut } from 'firebase/auth';
import { UserContext } from 'context/userContext';
import PlaceholderPng from 'assets/images/placeholder.png';

const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  { name: 'Make Decision', href: '/decision', icon: UsersIcon },
  {
    name: 'Priority Order',
    href: '/priority-order',
    icon: FolderIcon,
  },
  /*  {
    name: 'Habit Tracker',
    href: '/habit-tracker',
    icon: CalendarIcon,
  }, */
  {
    name: 'Content Ideas',
    href: '/content-ideas',
    icon: InboxIcon,
  },
];

const userNavigation = [
  {
    name: 'Account Settings',
    href: '/account',
    icon: UserIcon,
    current: false,
  },
  {
    name: 'Pricing',
    href: '/billing',
    icon: CurrencyDollarIcon,
    current: false,
  },
  {
    name: 'Personal Plan',
    href: '/personal-plan',
    icon: RocketLaunchIcon,
    current: false,
  },
  {
    name: 'Sign out',
    href: '#',
    icon: ArrowRightOnRectangleIcon,
    current: false,
  },
];

const questions = [
  {
    name: 'Upgrade to Pro',
    href: '/billing',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Reach me to help',
    href: '#',
    icon: InformationCircleIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const userCtx = useContext(UserContext);

  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate('/login', {
          replace: true,
        });
      })
      .catch((error) => {});
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img
                        className="h-8 w-auto"
                        src={Logo}
                        alt="Your Company"
                      />
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {navigation.map((item) => (
                        <>
                          <NavLink
                            end
                            key={item.name}
                            to={item.href}
                            children={({ isActive }) => (
                              <>
                                <item.icon
                                  className={classNames(
                                    isActive
                                      ? 'text-secondary'
                                      : 'text-gray-500 group-hover:text-gray-500',
                                    'mr-3 flex-shrink-0 h-5 w-5'
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </>
                            )}
                            className={({ isActive }) =>
                              classNames(
                                isActive
                                  ? 'bg-primary text-secondary font-bold '
                                  : 'text-gray-500 hover:bg-gray-50 hover:text-gray-900 font-medium ',
                                'group flex items-center px-2 py-2 text-sm rounded-md'
                              )
                            }
                          />
                        </>
                      ))}
                    </nav>
                  </div>
                  <div className=" bg-white px-2">
                    <div>
                      <Link
                        to="billing"
                        className={
                          'text-gray-500 mb-1 hover:bg-gray-50 hover:text-gray-900 font-medium group  items-center px-2 py-2 text-sm rounded-md flex'
                        }
                      >
                        <CurrencyDollarIcon className="mr-3 flex-shrink-0 h-5 w-5" />
                        <div>Upgrade Pro</div>
                      </Link>
                    </div>
                    <div>
                      <a
                        href="mailto:ozkan.arslan.dev@gmail.com"
                        className={
                          'text-gray-500 mb-1 hover:bg-gray-50 hover:text-gray-900 font-medium group  items-center px-2 py-2 text-sm rounded-md flex'
                        }
                      >
                        <InformationCircleIcon className="mr-3 flex-shrink-0 h-5 w-5" />
                        <div>Contact us</div>
                      </a>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <img className="h-8 w-auto" src={Logo} alt="Your Company" />
              </div>
              <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
                {navigation.map((item) => (
                  <>
                    <NavLink
                      end
                      key={item.name}
                      to={item.href}
                      children={({ isActive }) => (
                        <>
                          <item.icon
                            className={classNames(
                              isActive
                                ? 'text-secondary'
                                : 'text-gray-500 group-hover:text-gray-500',
                              'mr-3 flex-shrink-0 h-5 w-5'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </>
                      )}
                      className={({ isActive }) =>
                        classNames(
                          isActive
                            ? 'bg-primary text-secondary font-bold '
                            : 'text-gray-500 hover:bg-gray-50 hover:text-gray-900 font-medium ',
                          'group flex items-center px-2 py-2 text-sm rounded-md'
                        )
                      }
                    />
                  </>
                ))}
              </nav>
            </div>

            <div className=" bg-white px-2">
              <div>
                <Link
                  to="/billing"
                  className={
                    'text-gray-500 mb-1 hover:bg-gray-50 hover:text-gray-900 font-medium group  items-center px-2 py-2 text-sm rounded-md flex'
                  }
                >
                  <CurrencyDollarIcon className="mr-3 flex-shrink-0 h-5 w-5" />
                  <div>Upgrade Pro</div>
                </Link>
              </div>
              <div>
                <a
                  href="mailto:ozkan.arslan.dev@gmail.com"
                  className={
                    'text-gray-500 mb-1 hover:bg-gray-50 hover:text-gray-900 font-medium group  items-center px-2 py-2 text-sm rounded-md flex'
                  }
                >
                  <InformationCircleIcon className="mr-3 flex-shrink-0 h-5 w-5" />
                  <div>Contact us</div>
                </a>
              </div>
            </div>
            <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
              <div className="group block w-full flex-shrink-0">
                <div className="flex items-center ">
                  <Menu as="div" className="relative w-full ">
                    <div className="w-full bg-white">
                      <Menu.Button className="flex w-full items-center rounded-full bg-white text-sm focus:outline-none ">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={PlaceholderPng}
                          alt=""
                        />
                        <div className="ml-3 overflow-hidden">
                          <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 truncate">
                            {userCtx.name ?? userCtx.email}
                          </p>
                          <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                            View profile
                          </p>
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute -translate-y-full left-0 translate-x-full  z-[99]  mt-2 w-48 rounded-md bg-white py-1 shadow ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <div>
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link
                                  onClick={() => {
                                    if (item.name === 'Sign out') {
                                      handleSignOut();
                                    }
                                  }}
                                  to={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'px-4 py-2 text-sm text-gray-700 flex items-center'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      'text-gray-500 group-hover:text-gray-500',
                                      'mr-3 flex-shrink-0 h-5 w-5'
                                    )}
                                  />
                                  {item.name}
                                </Link>
                              )}
                            </Menu.Item>
                          </div>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:pl-64">
          <div className="sticky top-0 z-10  h-14 flex flex-shrink-0 bg-white shadow-sm md:hidden">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1"></div>

              <div className="ml-4 flex items-center md:ml-6">
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                      <span className="sr-only">View notifications</span>
                      <QuestionMarkCircleIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {questions.map((item) => (
                        <div>
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'px-4 py-2 text-sm text-gray-700 flex items-center'
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    'text-gray-500 group-hover:text-gray-500',
                                    'mr-3 flex-shrink-0 h-5 w-5'
                                  )}
                                />
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        </div>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={PlaceholderPng}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <div>
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.href}
                                onClick={() => {
                                  if (item.name === 'Sign out') {
                                    handleSignOut();
                                  }
                                }}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'px-4 py-2 text-sm text-gray-700 flex items-center'
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    'text-gray-500 group-hover:text-gray-500',
                                    'mr-3 flex-shrink-0 h-5 w-5'
                                  )}
                                />
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        </div>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <div className="bg-mainBgColor py-3 px-6 md:py-6 md:px-12 min-h-screen h-full">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
