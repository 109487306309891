import React, { useContext, useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';

import { UserContext } from 'context/userContext';
import { db } from 'firebaseConfig';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import Loading from 'components/UI/Loading';

const tiers = [
  {
    identifier: 'basic',
    name: 'Free',
    href: '#',
    priceMonthly: 0,
    description: 'All the basics for starting a new user',
    includedFeatures: [
      'Limited to 5 decisions',
      'Limited to 5 order',
      'Limited to 5 content ideas',
    ],
  },
  {
    identifier: 'pro',
    name: 'Pro',
    href: '#',
    priceMonthly: 8,
    description: 'All the limitlessness for a pro user',
    includedFeatures: [
      'Unlimited decisions',
      'Unlimited order',
      'Unlimited content ideas',
      'Priority support',
    ],
  },
];

const BillingPage = () => {
  const userCtx = useContext(UserContext);
  const [planActive, setPlanActive] = useState(false);

  const [loadingInfo, setLoadingInfo] = useState(false);

  function checkoutClosed(data) {
    console.log(data);
    alert('Your purchase has been cancelled, we hope to see you again soon!');
  }

  async function checkoutComplete(data) {
    console.log(data);
    alert('Thanks for your purchase.');

    if (data?.checkout?.completed) {
      console.log('Purchase completed!', data.checkout.completed.id);

      const userSnap = doc(db, 'users', userCtx.id);
      await updateDoc(userSnap, {
        plan: 'pro',
      }).then(() => {
        setPlanActive(true);
      });
    }
  }

  const getInfo = async () => {
    setLoadingInfo(true);
    const docSnap = await getDoc(doc(db, 'users', userCtx.id));

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      // setIncomingData(docSnap.data());
      if (docSnap.data().plan === 'pro') {
        setPlanActive(true);
      }
    }
    setLoadingInfo(false);
  };

  useEffect(() => {
    getInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //cancel checkout

  return (
    <div>
      {loadingInfo ? (
        <Loading />
      ) : (
        <div className="flex flex-col space-y-4 w-full">
          <div className="flex gap-2 items-center text-lg font-medium mb-2">
            <CurrencyDollarIcon className="h-6 w-6" />
            <div>Pricing</div>
          </div>
          <div className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-3xl xl:grid-cols-2">
            {tiers.map((tier) => (
              <div
                key={tier.name}
                className="divide-y bg-white  divide-gray-200 rounded-lg border border-gray-200 shadow-sm"
              >
                <div className="p-6">
                  <h2 className="text-lg font-medium leading-6 text-gray-900">
                    {tier.name}
                  </h2>
                  <p className="mt-4 text-sm text-gray-500">
                    {tier.description}
                  </p>
                  <p className="mt-8">
                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                      ${tier.priceMonthly}
                    </span>{' '}
                    <span className="text-base font-medium text-gray-500">
                      {tier.priceMonthly > 0 ? '/one time payment' : ''}
                    </span>
                  </p>
                  {tier.identifier === 'pro' ? (
                    <button
                      disabled={planActive}
                      onClick={() =>
                        window.Paddle.Checkout.open({
                          product: 38040,
                          successCallback: checkoutComplete,
                          closeCallback: checkoutClosed,
                        })
                      }
                      className="mt-8 block w-full rounded-md py-2 text-center text-sm font-semibold text-white transition-all duration-500 bg-gradient-to-tl from-pink-500 via-pink-300 to-blue-500 bg-size-200 bg-pos-0 hover:bg-pos-100"
                    >
                      {planActive ? 'Your Plan' : 'Buy ' + tier.name}
                    </button>
                  ) : (
                    <div className="mt-16"></div>
                  )}
                </div>
                <div className="px-6 pt-6 pb-8">
                  <h3 className="text-sm font-medium text-gray-900">
                    What's included
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {tier.includedFeatures.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon
                          className="h-5 w-5 flex-shrink-0 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingPage;
