import { InboxIcon, PlusIcon, TvIcon } from '@heroicons/react/24/outline';
import Button from 'components/UI/Button';
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  collection,
  getDocs,
  doc,
  setDoc,
  query,
  where,
} from 'firebase/firestore';
import { db } from 'firebaseConfig';
import { useEffect } from 'react';
import Loading from 'components/UI/Loading';
import { UserContext } from 'context/userContext';

const ContentIdeasPage = () => {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  const [loading, setLoading] = React.useState(false);
  const [ideas, setIdeas] = React.useState([]);

  const getDecisions = async () => {
    setLoading(true);
    const tempData = [];
    const querySnapshot = await getDocs(
      query(collection(db, 'contents'), where('userId', '==', userCtx.id))
    );
    querySnapshot.forEach((doc) => {
      tempData.push({ id: doc.id, ...doc.data() });
    });
    setIdeas(tempData);
    setLoading(false);
  };

  useEffect(() => {
    getDecisions();
  }, []);

  useEffect(() => {
    // remove all /n from the content
    /*   const tempIdeas = ideas.map((idea) => {
      return { ...idea, content: idea.content.replace(/\\n/g, '') };
    }); */

    console.log(ideas);
  }, [ideas]);
  return (
    <div>
      <div className="flex gap-2 items-center text-lg font-medium w-full">
        <InboxIcon className="h-6 w-6" />
        <div>Content Ideas</div>
      </div>

      <div>
        <div className="font-semibold text-lg border-b-2 border-gray-300 mt-5">
          Create Content Idea
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div className="flex flex-wrap gap-4 pt-4">
            {ideas?.map((idea, index) => (
              <div
                key={index}
                onClick={() => navigate(`/content-ideas/${idea.id}`)}
                className="w-96 overflow-hidden shadow bg-white rounded-lg flex justify-between flex-col cursor-pointer"
              >
                <div>
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2 truncate">
                      {idea.text}
                    </div>
                    <p className="text-gray-700 text-base">
                      {idea.result?.reduce((acc, curr) => {
                        console.log({ curr });
                        return acc + curr;
                      }, '')}
                    </p>
                  </div>
                </div>

                <div className="px-6 pt-4 pb-2">
                  <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #{idea.text.split(' ')[0]}
                  </span>
                </div>
              </div>
            ))}

            <div className="flex gap-2 items-center justify-between border border-gray-200 rounded p-3 bg-white w-96 min-h-[210px]">
              <div className=" border-2 border-dashed h-full w-full flex justify-center items-center">
                <div className="text-gray-500 flex items-center gap-2">
                  <div className="flex items-center">
                    <PlusIcon className="h-4 w-4" />
                    <TvIcon className="h-6 w-6" />
                  </div>
                  <Link to="/content-ideas/new">
                    <div> Create a new card</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/*    <div>
        <div className="font-semibold text-lg border-b-2 border-gray-300 mt-5">
          Complete My Sentences
        </div>
        <div className="flex gap-4 pt-4">
          <div className="max-w-sm overflow-hidden shadow bg-white rounded-lg">
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
              <p className="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Voluptatibus quia, nulla! Maiores et perferendis eaque,
                exercitationem praesentium nihil.
              </p>
            </div>
            <div className="px-6 pt-4 pb-2">
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                #photography
              </span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                #travel
              </span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                #winter
              </span>
            </div>
          </div>

          <div className="flex gap-2 items-center justify-between border border-gray-200 rounded p-3 bg-white w-96 ">
            <div className=" border-2 border-dashed h-full w-full flex justify-center items-center">
              <div className="text-gray-500 flex items-center gap-2">
                <div className="flex items-center">
                  <PlusIcon className="h-4 w-4" />
                  <TvIcon className="h-6 w-6" />
                </div>
                <div> Create a new card</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ContentIdeasPage;
