import Button from 'components/UI/Button';
import TextArea from 'components/UI/TextArea';
import { db } from 'firebaseConfig';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { collection, getDoc, doc, deleteDoc } from 'firebase/firestore';
import Loading from 'components/UI/Loading';

const ContentDetailPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [incomingData, setIncomingData] = useState({});

  const [loadingDelete, setLoadingDelete] = useState(false);

  const getDecisions = async () => {
    setLoading(true);
    const docSnap = await getDoc(doc(db, 'contents', params.id));

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      setIncomingData(docSnap.data());
    }
    setLoading(false);
  };

  useEffect(() => {
    getDecisions();
  }, []);

  const handleDelete = async () => {
    setLoadingDelete(true);
    await deleteDoc(doc(db, 'contents', params.id));
    setLoadingDelete(false);
    navigate('/content-ideas');
  };

  return (
    <div>
      <div className="flex gap-2 items-center text-lg font-medium justify-between w-full">
        <div>Content Idea Detail</div>
        <Button
          isLoading={loadingDelete}
          onClick={handleDelete}
          className="bg-red-600 hover:bg-red-700 focus:ring-red-500"
        >
          Delete
        </Button>
      </div>

      <div>
        <div className="flex gap-2 items-center text-sm text-gray-500  my-2">
          <Link to="/content-ideas">{'<'} Back to content ideas page</Link>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-5 mt-5">
            <div>
              <div className="text-sm">Enter your keywords or sentence</div>
              <TextArea
                value={incomingData.text}
                disabled
                className="disabled:bg-white resize-none"
              />
            </div>
          </div>
          <div>
            <div className="text-sm">Enter your keywords or sentence</div>
            <TextArea
              rows={15}
              value={incomingData?.result?.join('\n')}
              disabled
              className="disabled:bg-white resize-none"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentDetailPage;
