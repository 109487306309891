import { auth } from 'firebaseConfig';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import Loading from 'components/UI/Loading';

const PublicRoute = () => {
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // ...
      }
      setLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Loading />
        </div>
      ) : user ? (
        navigate('/', { replace: true })
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default PublicRoute;
