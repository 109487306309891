import { UserIcon } from '@heroicons/react/24/outline';
import EditAvatarModal from 'components/EditAvatarModal';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import TextArea from 'components/UI/TextArea';
import WhiteButton from 'components/UI/WhiteButton';
import React, { useContext, useEffect, useRef, useState } from 'react';
import PlaceholderPng from 'assets/images/placeholder.png';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { UserContext } from 'context/userContext';
import { db } from 'firebaseConfig';

import { getDoc, doc, updateDoc } from 'firebase/firestore';
import Loading from 'components/UI/Loading';
import toast, { Toaster } from 'react-hot-toast';

const AccountPage = () => {
  const userCtx = useContext(UserContext);

  const [displayName, setDisplayName] = useState('');
  const [about, setAbout] = useState('');
  const [loadingInfo, setLoadingInfo] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState('');
  const imageRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    if (imageFile) {
      setLoading(true);

      const storage = getStorage();

      const storageRef = ref(storage, 'user_images/' + imageFile.name);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
            default:
              break;

            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
          setLoading(false);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref)
            .then(async (downloadURL) => {
              console.log('File available at', downloadURL);

              // set url to firestore user
              const userSnap = doc(db, 'users', userCtx.id);

              await updateDoc(userSnap, {
                avatar: downloadURL,
                displayName: displayName,
                about: about,
              }).then(() => {
                toast.success('Saved successfully');
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      );
    } else {
      setLoading(true);
      const userSnap = doc(db, 'users', userCtx.id);

      updateDoc(userSnap, {
        displayName: displayName,
        about: about,
      })
        .then(() => {
          toast.success('Saved successfully');
        })
        .finally(() => {
          setLoading(false);
        });
    }

    // update user profile
  };

  const getInfo = async () => {
    setLoadingInfo(true);
    const docSnap = await getDoc(doc(db, 'users', userCtx.id));

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      // setIncomingData(docSnap.data());
      if (docSnap.data().displayName) {
        setDisplayName(docSnap.data().displayName);
      }
      if (docSnap.data().about) {
        setAbout(docSnap.data().about);
      }
      if (docSnap.data().avatar) {
        setImage(docSnap.data().avatar);
      }
    }
    setLoadingInfo(false);
  };

  useEffect(() => {
    getInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-mainBgColor">
      <Toaster />

      {loadingInfo ? (
        <Loading />
      ) : (
        <form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="flex gap-2 items-center text-lg font-medium">
                <UserIcon className="h-5 w-5" />
                <div>Profile</div>
              </div>

              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Display Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="flex max-w-lg rounded-md shadow-sm">
                      <Input
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    About
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <TextArea
                      id="about"
                      name="about"
                      rows={3}
                      className="max-w-lg"
                      defaultValue={''}
                      value={about}
                      onChange={(e) => setAbout(e.target.value)}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Write a few sentences about yourself.
                    </p>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="photo"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Photo
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <EditAvatarModal
                      modalState={isOpen}
                      setModalState={() => setIsOpen(false)}
                      setFile={setImageFile}
                      setImage={setImage}
                    >
                      <div></div>
                    </EditAvatarModal>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        <div className="mt-1 flex items-center">
                          <img
                            ref={imageRef}
                            src={image ? image : PlaceholderPng}
                            alt=""
                            className="rounded-full border border-borderStroke h-12 w-12"
                            width={100}
                          />

                          <button
                            type="button"
                            onClick={() => setIsOpen(!isOpen)}
                            className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2"
                          >
                            Change
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex gap-4 justify-end">
              {/* <WhiteButton>Cancel</WhiteButton> */}
              <Button isLoading={loading} onClick={handleSave}>
                Save
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AccountPage;
