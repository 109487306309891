import React from 'react';

const TextArea = (props) => {
  const { placeholder, className, rows = 4, ...rest } = props;
  return (
    <textarea
      {...rest}
      rows={rows}
      placeholder={placeholder}
      className={`textarea ${className}`}
    />
  );
};

export default TextArea;
