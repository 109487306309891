import {
  RectangleStackIcon,
  StarIcon,
  TrashIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';

import {
  StarIcon as StarIconSolid,
  TrashIcon as TrashIconSolid,
} from '@heroicons/react/24/solid';

import Card from 'components/Card/Card';
import Button from 'components/UI/Button';
import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  collection,
  getDocs,
  doc,
  setDoc,
  query,
  where,
} from 'firebase/firestore';
import { db } from 'firebaseConfig';
import Loading from 'components/UI/Loading';
import { UserContext } from 'context/userContext';

const PriorityListPage = () => {
  const userCtx = useContext(UserContext);

  const [selected, setSelected] = React.useState('all');
  const [loading, setLoading] = React.useState(false);
  const [priorities, setPriorities] = React.useState([]);

  const navigate = useNavigate();

  const getDecisions = async () => {
    setLoading(true);
    const tempData = [];
    const querySnapshot = await getDocs(
      query(collection(db, 'priorities'), where('userId', '==', userCtx.id))
    );
    querySnapshot.forEach((doc) => {
      tempData.push({ id: doc.id, ...doc.data() });
    });
    setPriorities(tempData);
    setLoading(false);
  };

  useEffect(() => {
    getDecisions();
  }, []);

  const handleMoveDelete = async (id) => {
    const tempDecisions = { ...priorities };
    delete tempDecisions[id];

    const decision = doc(db, 'priorities', id);
    await setDoc(decision, {
      ...priorities.find((d) => d.id === id),
      deleted: !priorities.find((d) => d.id === id).deleted,
    });

    setPriorities((prev) => {
      return prev.map((d) => {
        if (d.id === id) {
          return { ...d, deleted: !d.deleted };
        }
        return d;
      });
    });
  };

  const handleFavorite = async (id) => {
    const tempDecisions = { ...priorities };
    delete tempDecisions[id];

    const decision = doc(db, 'priorities', id);
    await setDoc(decision, {
      ...priorities.find((d) => d.id === id),
      favorite: !priorities.find((d) => d.id === id).favorite,
    });

    setPriorities((prev) => {
      return prev.map((d) => {
        if (d.id === id) {
          return { ...d, favorite: !d.favorite };
        }
        return d;
      });
    });
  };

  return (
    <div className="space-y-3">
      <div className="flex gap-2 items-center text-lg font-medium">
        <RectangleStackIcon className="h-6 w-6" />
        <div>All Orders</div>
      </div>

      <div className="flex justify-end">
        <Button onClick={() => navigate('/priority-order/new')}>
          + New Priority
        </Button>
      </div>

      <div className="flex gap-2">
        <div
          onClick={() => setSelected('all')}
          className={`flex items-center gap-1 py-1 px-2 rounded cursor-pointer font-medium transition-all ${
            selected === 'all' ? 'bg-blue-100 text-indigo-700' : 'text-gray-600'
          }`}
        >
          <RectangleStackIcon className="h-4 w-4" />
          <div className="text-sm">All</div>
        </div>
        <div
          onClick={() => setSelected('starred')}
          className={`flex items-center gap-1 py-1 px-2 rounded cursor-pointer font-medium  transition-all ${
            selected === 'starred'
              ? 'bg-blue-100 text-indigo-700'
              : 'text-gray-500'
          }`}
        >
          <StarIcon className="h-4 w-4" />
          <div className="text-sm">Favorite</div>
        </div>
        <div
          onClick={() => setSelected('trash')}
          className={`flex items-center gap-1 py-1 px-2 rounded cursor-pointer font-medium  transition-all ${
            selected === 'trash'
              ? 'bg-blue-100 text-indigo-700'
              : 'text-gray-500'
          }`}
        >
          <TrashIcon className="h-4 w-4" />
          <div className="text-sm">Trash</div>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="overflow-x-auto relative ">
          <table className="w-full text-sm text-left text-gray-500 mt-2">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="py-3">
                  Priority name
                </th>
                <th scope="col" className="py-3 px-6">
                  <div className="flex items-center">
                    Low
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-1 w-3 h-3"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 320 512"
                      >
                        <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"></path>
                      </svg>
                    </a>
                  </div>
                </th>
                <th scope="col" className="py-3 px-6">
                  <div className="flex items-center">
                    Medium
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-1 w-3 h-3"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 320 512"
                      >
                        <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"></path>
                      </svg>
                    </a>
                  </div>
                </th>
                <th scope="col" className="py-3 px-6">
                  <div className="flex items-center">
                    High
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-1 w-3 h-3"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 320 512"
                      >
                        <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"></path>
                      </svg>
                    </a>
                  </div>
                </th>
                <th scope="col" className="py-3 px-6">
                  <div className="flex items-center">
                    Very High
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-1 w-3 h-3"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 320 512"
                      >
                        <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"></path>
                      </svg>
                    </a>
                  </div>
                </th>
                <th scope="col" className="py-3 px-6">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {priorities
                .filter(
                  (priority) =>
                    (selected === 'all' && !priority?.deleted) ||
                    (selected === 'starred' &&
                      priority?.favorite &&
                      !priority?.deleted) ||
                    (selected === 'trash' && priority?.deleted)
                )
                .map((priority) => (
                  <tr className="bg-transparent border-b hover:bg-gray-200/50  ">
                    <th
                      scope="row"
                      className="py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      {priority.title}
                    </th>
                    <td className="py-4 px-6">{priority.lowCard?.length}</td>
                    <td className="py-4 px-6">{priority.mediumCard?.length}</td>
                    <td className="py-4 px-6">{priority.highCard?.length}</td>
                    <td className="py-4 px-6">
                      {priority.veryHighCard?.length}
                    </td>

                    <td className="py-4 px-6 text-right flex justify-end gap-3">
                      <Link
                        to={`/priority-order/${priority.id}`}
                        className="font-medium text-blue-600  hover:underline cursor-pointer"
                      >
                        <PencilSquareIcon className="h-5 w-5" />
                      </Link>
                      <div
                        onClick={() => handleFavorite(priority.id)}
                        className="font-medium text-blue-600  hover:underline cursor-pointer"
                      >
                        {priority.favorite ? (
                          <StarIconSolid className="h-5 w-5 text-yellow-500" />
                        ) : (
                          <StarIcon className="h-5 w-5 text-yellow-700" />
                        )}
                      </div>
                      <Link
                        onClick={() => handleMoveDelete(priority.id)}
                        className="font-medium text-blue-600  hover:underline cursor-pointer"
                      >
                        {priority.deleted ? (
                          <TrashIconSolid className="h-5 w-5 text-red-500" />
                        ) : (
                          <TrashIcon className="h-5 w-5 text-red-500" />
                        )}
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PriorityListPage;
