import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  CodeBracketIcon,
  MinusIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  StarIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import Button from 'components/UI/Button';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { db } from 'firebaseConfig';
import Loading from 'components/UI/Loading';

const PriorityDetailPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [lowCard, setLowCard] = useState([]);
  const [mediumCard, setMediumCard] = useState([]);
  const [highCard, setHighCard] = useState([]);
  const [veryHighCard, setVeryHighCard] = useState([]);

  console.log({ lowCard });

  const [incomingData, setIncomingData] = useState([]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const getPriorities = async () => {
    setLoading(true);
    const docSnap = await getDoc(doc(db, 'priorities', id));

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());

      // setVeryHighCard(docSnap.data().veryHigh);
      setHighCard(docSnap.data().highCard);
      setMediumCard(docSnap.data().mediumCard);
      setLowCard(docSnap.data().lowCard);
      setTitle(docSnap.data().title);
    }

    setLoading(false);
  };

  useEffect(() => {
    getPriorities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {' '}
          <div className="flex gap-2 items-center text-lg font-medium justify-between w-full">
            <div>{title}</div>
          </div>
          <div>
            <div className="flex gap-2 items-center text-sm text-gray-500  my-2">
              <Link to="/decision">{'<'} Back to decision page</Link>
            </div>
          </div>
          <div className="flex gap-5 flex-col lg:flex-row ">
            <div
              className="bg-white w-full md:w-80 mt-3 border border-gray-200 rounded-md shadow-sm overflow-y-auto "
              style={{
                height: 'calc(100vh - 8rem)',
              }}
            >
              <div className="relative flex justify-center items-center px-4 py-3 border-b border-gray-200 bg-gray-100/50 text-red-600 font-semibold">
                <div>Low</div>
                <div className="absolute right-2 cursor-pointer">
                  <PlusCircleIcon className="h-6 w-6 text-green-600" />
                </div>
              </div>

              <div className="flex gap-2 flex-col mt-2 px-3  pb-3">
                {lowCard?.map((card) => (
                  <div className="shadow border border-gray-200 rounded w-full h-full p-2 flex flex-col gap-1 relative">
                    <div className="flex flex-shrink-0 self-center absolute right-0 top-2">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="!#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'flex px-4 py-2 text-sm'
                                    )}
                                  >
                                    <TrashIcon
                                      className="mr-3 h-5 w-5 text-red-400"
                                      aria-hidden="true"
                                    />
                                    <span>Trash</span>
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="!#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'flex px-4 py-2 text-sm'
                                    )}
                                  >
                                    <PencilSquareIcon className="mr-3 h-5 w-5 text-black-400" />
                                    <span>Edit</span>
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <div className="font-medium">{card.header}</div>
                    <div className="text-gray-500 text-sm">
                      {card.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div
              className="bg-white w-full md:w-80 mt-3 border border-gray-200 rounded-md shadow-sm overflow-y-auto "
              style={{
                height: 'calc(100vh - 8rem)',
              }}
            >
              <div className="relative flex justify-center items-center px-4 py-3 border-b border-gray-200 bg-gray-100/50 text-orange-600 font-semibold">
                <div>Medium</div>

                <div className="absolute right-2 cursor-pointer">
                  <PlusCircleIcon className="h-6 w-6 text-green-600" />
                </div>
              </div>

              <div className="flex gap-2 flex-col mt-2 px-3  pb-3">
                {mediumCard?.map((card) => (
                  <div className="shadow border border-gray-200 rounded w-full h-full p-2 flex flex-col gap-1 relative">
                    <div className="flex flex-shrink-0 self-center absolute right-0 top-2">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="!#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'flex px-4 py-2 text-sm'
                                    )}
                                  >
                                    <TrashIcon
                                      className="mr-3 h-5 w-5 text-red-400"
                                      aria-hidden="true"
                                    />
                                    <span>Trash</span>
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="!#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'flex px-4 py-2 text-sm'
                                    )}
                                  >
                                    <PencilSquareIcon className="mr-3 h-5 w-5 text-black-400" />
                                    <span>Edit</span>
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <div className="font-medium">{card.header}</div>
                    <div className="text-gray-500 text-sm">
                      {card.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div
              className="bg-white w-full md:w-80 mt-3 border border-gray-200 rounded-md shadow-sm overflow-y-auto "
              style={{
                height: 'calc(100vh - 8rem)',
              }}
            >
              <div className="relative flex justify-center items-center px-4 py-3 border-b border-gray-200 bg-gray-100/50 text-blue-600 font-semibold">
                <div>High</div>

                <div className="absolute right-2 cursor-pointer">
                  <PlusCircleIcon className="h-6 w-6 text-green-600" />
                </div>
              </div>

              <div className="flex gap-2 flex-col mt-2 px-3  pb-3">
                {highCard?.map((card) => (
                  <div className="shadow border border-gray-200 rounded w-full h-full p-2 flex flex-col gap-1 relative">
                    <div className="flex flex-shrink-0 self-center absolute right-0 top-2">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="!#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'flex px-4 py-2 text-sm'
                                    )}
                                  >
                                    <TrashIcon
                                      className="mr-3 h-5 w-5 text-red-400"
                                      aria-hidden="true"
                                    />
                                    <span>Trash</span>
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="!#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'flex px-4 py-2 text-sm'
                                    )}
                                  >
                                    <PencilSquareIcon className="mr-3 h-5 w-5 text-black-400" />
                                    <span>Edit</span>
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <div className="font-medium">{card.header}</div>
                    <div className="text-gray-500 text-sm">
                      {card.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div
              className="bg-white w-full md:w-80 mt-3 border border-gray-200 rounded-md shadow-sm overflow-y-auto "
              style={{
                height: 'calc(100vh - 8rem)',
              }}
            >
              <div className="relative flex justify-center items-center px-4 py-3 border-b border-gray-200 bg-gray-100/50 text-green-600 font-semibold">
                <div>Very High</div>

                <div className="absolute right-2 cursor-pointer">
                  <PlusCircleIcon className="h-6 w-6 text-green-600" />
                </div>
              </div>

              <div className="flex gap-2 flex-col mt-2 px-3  pb-3">
                {veryHighCard?.map((card) => (
                  <div className="shadow border border-gray-200 rounded w-full h-full p-2 flex flex-col gap-1 relative">
                    <div className="flex flex-shrink-0 self-center absolute right-0 top-2">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="!#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'flex px-4 py-2 text-sm'
                                    )}
                                  >
                                    <TrashIcon
                                      className="mr-3 h-5 w-5 text-red-400"
                                      aria-hidden="true"
                                    />
                                    <span>Trash</span>
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="!#"
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'flex px-4 py-2 text-sm'
                                    )}
                                  >
                                    <PencilSquareIcon className="mr-3 h-5 w-5 text-black-400" />
                                    <span>Edit</span>
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <div className="font-medium">{card.header}</div>
                    <div className="text-gray-500 text-sm">
                      {card.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>{' '}
        </>
      )}
    </div>
  );
};

export default PriorityDetailPage;
