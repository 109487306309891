import { auth } from 'firebaseConfig';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { UserContext } from 'context/userContext';
import Loading from 'components/UI/Loading';

const PrivateRoute = () => {
  const userCtx = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const email = user.email;
        const displayName = user.displayName;
        const uid = user.uid;
        userCtx.setUserInfo(displayName, email, uid);
        // ...
      } else {
        // User is signed out
        navigate('/login', {
          replace: true,
        });
      }
      setLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Loading />
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default PrivateRoute;
