import { createContext, useState } from 'react';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ email: '', name: '', id: '' });

  const value = {
    name: user.name,
    email: user.email,
    id: user.id,
    setUserInfo: (name, email, uid) =>
      setUser({ name: name, email: email, id: uid }),
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
