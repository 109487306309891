import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  Navigate,
} from 'react-router-dom';

import HomePage from 'pages/Dashboard/HomePage';
import { ErrorBoundary } from 'pages/ErrorBoundary';
import Dashboard from 'components/Layout';

// account
import AccountPage from 'pages/Profile/AccountPage';
import BillingPage from 'pages/Profile/BillingPage';
import PersonalPlanPage from 'pages/Profile/PersonalPlanPage';

// auth
import RegisterPage from 'pages/Auth/RegisterPage';
import LoginPage from 'pages/Auth/LoginPage';

import HabitTrackerPage from 'pages/HabitTracker/HabitTrackerPage';
import ContentIdeasPage from 'pages/ContentIdeas/ContentIdeasPage';
import DecisionListPage from 'pages/Decision/DecisionListPage';
import DecisionDetailPage from 'pages/Decision/DecisionDetailPage';
import NewDecisionPage from 'pages/Decision/NewDecisionPage';
import PriorityListPage from 'pages/Priority/PriorityListPage';
import PriorityDetailPage from 'pages/Priority/PriorityDetailPage';
import NewPriorityPage from 'pages/Priority/NewPriorityPage';
import ContentDetailPage from 'pages/ContentIdeas/ContentDetailPage';
import CreateContentPage from 'pages/ContentIdeas/CreateContentPage';

import { analytics } from './firebaseConfig';
import { UserProvider } from 'context/userContext';
import PrivateRoute from 'components/routes/PrivateRoute';
import PublicRoute from 'components/routes/PublicRoute';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route element={<PrivateRoute />}>
          <Route element={<Dashboard />}>
            <Route index element={<HomePage />} />

            <Route path="decision" element={<DecisionListPage />} />
            <Route path="decision/:id" element={<DecisionDetailPage />} />
            <Route path="decision/new" element={<NewDecisionPage />} />

            <Route path="priority-order" element={<PriorityListPage />} />
            <Route path="priority-order/:id" element={<PriorityDetailPage />} />
            <Route path="priority-order/new" element={<NewPriorityPage />} />

            <Route path="habit-tracker" element={<HabitTrackerPage />} />

            <Route path="content-ideas" element={<ContentIdeasPage />} />
            <Route path="content-ideas/:id" element={<ContentDetailPage />} />
            <Route path="content-ideas/new" element={<CreateContentPage />} />

            <Route path="account" element={<AccountPage />} />
            <Route path="billing" element={<BillingPage />} />
            <Route path="personal-plan" element={<PersonalPlanPage />} />
          </Route>
        </Route>

        <Route element={<PublicRoute />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
        </Route>

        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    )
  );
  return (
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  );
}

export default App;
