import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import Button from 'components/UI/Button';
import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { db } from 'firebaseConfig';
import Loading from 'components/UI/Loading';

const DecisionDetailPage = () => {
  const params = useParams();

  const [giveDecision, setGiveDecision] = React.useState('');

  const [title, setTitle] = React.useState('');
  const [incomingData, setIncomingData] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const getDecisions = async () => {
    setLoading(true);
    const docSnap = await getDoc(doc(db, 'decisions', params.id));

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      setIncomingData(docSnap.data());
      setTitle(docSnap.data().title);
    }

    setLoading(false);
  };

  useEffect(() => {
    getDecisions();
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleDecision = () => {
    if (
      incomingData?.minusCard.length === 0 ||
      incomingData?.plusCard.length === 0
    ) {
      alert('Please add at least one plus and minus card');
      return;
    } else {
      const minusTotal = incomingData?.minusCard.reduce(
        (total, currentValue) => total + currentValue.degree,
        0
      );
      const plusTotal = incomingData?.plusCard.reduce(
        (total, currentValue) => total + currentValue.degree,
        0
      );
      if (minusTotal > plusTotal) {
        setGiveDecision('minus');
      } else if (minusTotal < plusTotal) {
        setGiveDecision('plus');
      } else {
        setGiveDecision('equal');
      }
    }
  };

  return (
    <div>
      <div className="flex gap-2 items-center text-lg font-medium justify-between w-full">
        <div>{title}</div>
      </div>

      <div>
        <div className="flex gap-2 items-center text-sm text-gray-500  my-2">
          <Link to="/decision">{'<'} Back to decision page</Link>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="flex gap-5 flex-col lg:flex-row">
          <div
            className="bg-white w-full md:w-80 mt-3 border border-gray-200 rounded-md shadow-sm overflow-y-auto "
            style={{
              height: 'calc(100vh - 8rem)',
            }}
          >
            <div className="relative flex justify-center items-center px-4 py-3 border-b border-gray-200 bg-gray-100/50 text-red-600 font-semibold">
              <div>Minus</div>
              <div>
                <ChevronDoubleDownIcon className="h-5 w-5" />
              </div>
              <div className="absolute right-2 cursor-pointer">
                <PlusCircleIcon className="h-6 w-6 text-green-600" />
              </div>
            </div>

            <div className="flex gap-2 flex-col mt-2 px-3  pb-3">
              {incomingData?.minusCard?.map((card) => (
                <div className="shadow border border-gray-200 rounded w-full h-full p-2 flex flex-col gap-1 relative">
                  <div className="flex flex-shrink-0 self-center absolute right-0 top-2">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="!#"
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'flex px-4 py-2 text-sm'
                                  )}
                                >
                                  <TrashIcon
                                    className="mr-3 h-5 w-5 text-red-400"
                                    aria-hidden="true"
                                  />
                                  <span>Trash</span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="!#"
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'flex px-4 py-2 text-sm'
                                  )}
                                >
                                  <PencilSquareIcon className="mr-3 h-5 w-5 text-black-400" />
                                  <span>Edit</span>
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="font-medium">{card.title}</div>
                  <div className="text-gray-500 text-sm">
                    {card.description}
                  </div>
                  <div className="flex items-center gap-1 mt-2">
                    <ChevronDoubleDownIcon className="h-4 w-4 text-gray-500" />
                    <div className="text-gray-500 bg-gray-100 px-2 rounded-full text-sm">
                      {card.degree}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="bg-white w-full md:w-80 mt-3 border border-gray-200 rounded-md shadow-sm overflow-y-auto "
            style={{
              height: 'calc(100vh - 8rem)',
            }}
          >
            <div className="relative flex justify-center items-center px-4 py-3 border-b border-gray-200 bg-gray-100/50 text-green-600 font-semibold">
              <div>Plus</div>
              <div>
                <ChevronDoubleUpIcon className="h-5 w-5" />
              </div>
              <div className="absolute right-2 cursor-pointer">
                <PlusCircleIcon className="h-6 w-6 text-green-600" />
              </div>
            </div>

            <div className="flex gap-2 flex-col mt-2 px-3  pb-3">
              {incomingData?.plusCard?.map((card) => (
                <div className="shadow border border-gray-200 rounded w-full h-full p-2 flex flex-col gap-1 relative">
                  <div className="flex flex-shrink-0 self-center absolute right-0 top-2">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="!#"
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'flex px-4 py-2 text-sm'
                                  )}
                                >
                                  <TrashIcon
                                    className="mr-3 h-5 w-5 text-red-400"
                                    aria-hidden="true"
                                  />
                                  <span>Trash</span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="!#"
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'flex px-4 py-2 text-sm'
                                  )}
                                >
                                  <PencilSquareIcon className="mr-3 h-5 w-5 text-black-400" />
                                  <span>Edit</span>
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="font-medium">{card.title}</div>
                  <div className="text-gray-500 text-sm">
                    {card.description}
                  </div>
                  <div className="flex items-center gap-1 mt-2">
                    <ChevronDoubleDownIcon className="h-4 w-4 text-gray-500" />
                    <div className="text-gray-500 bg-gray-100 px-2 rounded-full text-sm">
                      {card.degree}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            className="bg-white w-full md:w-80 mt-3 border border-gray-200 rounded-md shadow-sm overflow-y-auto flex justify-center items-center p-5 text-center"
            style={{
              height: 'calc(100vh - 8rem)',
            }}
          >
            {giveDecision === 'plus' && (
              <div className="text-green-600 font-semibold">
                Plus decision is the best decision for you.
              </div>
            )}

            {giveDecision === 'minus' && (
              <div className="text-red-600 font-semibold">
                Minus decision is the best decision for you.
              </div>
            )}

            {giveDecision === 'equal' && (
              <div className="text-gray-600 font-semibold">
                Both decision are equal.
              </div>
            )}
          </div>

          <div className="flex justify-end items-end">
            <Button
              onClick={handleDecision}
              className="bg-green-600 hover:bg-green-700 focus:ring-green-300"
            >
              Give Decision
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DecisionDetailPage;
