import React from 'react';

const Select = (props) => {
  const { placeholder, classNames, ...rest } = props;
  return (
    <select
      {...rest}
      placeholder={placeholder}
      className={`select ${classNames}`}
    />
  );
};

export default Select;
