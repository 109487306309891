import {
  CalendarIcon,
  FolderIcon,
  InboxIcon,
  UsersIcon,
} from '@heroicons/react/20/solid';
import { HomeIcon, PlusIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div className="h-full">
      <div className="flex gap-2 items-center text-lg font-medium mb-2">
        <HomeIcon className="h-6 w-6" />
        <div>Dashboard</div>
      </div>

      <div className="divide-y-2 space-y-6">
        <div className="pt-3 ">
          <div className="font-bold text-xl">Decisions</div>

          {/*Description */}
          <div className="text-gray-500 text-sm">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptate, quod, quia, voluptates quae voluptatibus quibusdam
          </div>
          {/*create card */}
          <Link to="/decision">
            <div className="flex gap-2 items-center justify-between mt-3 border border-gray-200 rounded p-3 bg-white w-full sm:w-96 h-40">
              <div className=" border-2 border-dashed h-full w-full flex justify-center items-center">
                <div className="text-gray-500 flex items-center gap-2">
                  <div className="flex items-center">
                    <PlusIcon className="h-4 w-4" />
                    <UsersIcon className="h-6 w-6" />
                  </div>
                  <div> Create a new decision</div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="pt-3 ">
          <div className="font-bold text-xl">Priority Order</div>
          {/*Description */}
          <div className="text-gray-500 text-sm">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptate, quod, quia, voluptates quae voluptatibus quibusdam
          </div>
          {/*create card */}
          <Link to="/priority-order">
            <div className="flex gap-2 items-center justify-between mt-3 border border-gray-200 rounded p-3 bg-white w-full sm:w-96 h-40">
              <div className=" border-2 border-dashed h-full w-full flex justify-center items-center">
                <div className="text-gray-500 flex items-center gap-2">
                  <div className="flex items-center">
                    <PlusIcon className="h-4 w-4" />
                    <FolderIcon className="h-6 w-6" />
                  </div>
                  <div> Create a new priority</div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        {/*         <div className="pt-3 ">
          <div className="font-bold text-xl">Habit Tracker</div>
          <div className="text-gray-500 text-sm">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptate, quod, quia, voluptates quae voluptatibus quibusdam
          </div>
          <div className="flex gap-2 items-center justify-between mt-3 border border-gray-200 rounded p-3 bg-white w-full sm:w-96 h-40">
            <div className=" border-2 border-dashed h-full w-full flex justify-center items-center">
              <div className="text-gray-500 flex items-center gap-2">
                <div className="flex items-center">
                  <PlusIcon className="h-4 w-4" />
                  <CalendarIcon className="h-6 w-6" />
                </div>
                <div>Create a new habit</div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="pt-3 ">
          <div className="font-bold text-xl">Content Ideas</div>
          {/*Description */}
          <div className="text-gray-500 text-sm">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptate, quod, quia, voluptates quae voluptatibus quibusdam
          </div>
          {/*create card */}
          <Link to="/content-ideas">
            <div className="flex gap-2 items-center justify-between mt-3 border border-gray-200 rounded p-3 bg-white w-full sm:w-96 h-40">
              <div className=" border-2 border-dashed h-full w-full flex justify-center items-center">
                <div className="text-gray-500 flex items-center gap-2">
                  <div className="flex items-center">
                    <PlusIcon className="h-4 w-4" />
                    <InboxIcon className="h-6 w-6" />
                  </div>
                  <div> Create a new content</div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
