import GoogleIcon from 'assets/icons/google.svg';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { Link } from 'react-router-dom';
import Logo from 'assets/icons/logo.png';
import { useState } from 'react';
import { auth, db } from 'firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import Dashboard from 'assets/images/dashboard.png';

import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    if (!email || !password) {
      alert('Please fill in all fields');
      return;
    } else {
      setLoading(true);
      // ...
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user);
          // ...
        })
        .catch((error) => {
          const errorMessage = error.message;
          alert(errorMessage);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.

        const userRef = doc(db, 'users', auth.currentUser.uid);
        const docSnap = getDoc(userRef);

        if (docSnap.exists) {
          console.log('Document data:', docSnap.data());
        } else {
          setDoc(doc(db, 'users', auth.currentUser.uid), {
            displayName: auth.currentUser.displayName,
            email: auth.currentUser.email,
          });
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorMessage = error.message;
        alert(errorMessage);
        // ...
      });
  };

  return (
    <div className="grid lg:grid-cols-2 min-h-screen">
      <div className="col-span-1 shadow-2xl">
        {' '}
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              src={Logo}
              className="mx-auto h-10 w-auto"
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Welcome to Recatpaw
            </h2>
            <div className="text-center text-sm text-gray-700 mt-2">
              Don't have an account?{' '}
              <Link
                to="/register"
                className="text-blue-500 font-semibold hover:underline"
              >
                Sign up
              </Link>
            </div>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4  sm:px-10">
              <form className="space-y-6" onSubmit={handleLogin}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <Input
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <Input
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center"></div>

                  <div className="text-sm">
                    <div className="font-medium text-indigo-600 hover:text-indigo-500">
                      Forgot your password?
                    </div>
                  </div>
                </div>

                <div>
                  <Button type="submit" isLoading={loading} className="w-full">
                    Sign in
                  </Button>
                </div>
              </form>

              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-2 text-gray-500">
                      Or continue with
                    </span>
                  </div>
                </div>

                <div className="mt-6 ">
                  <div onClick={() => handleGoogleLogin()}>
                    <div className="cursor-pointer flex gap-4 w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
                      <img src={GoogleIcon} alt="" width={22} />

                      <span>Sign in with Google</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-1 relative hidden lg:block">
        {' '}
        {/* <img
          className="h-screen w-full object-cover"
          src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          alt=""
        /> */}
        <div className="bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 w-full h-full flex justify-center items-center">
          <img src={Dashboard} alt="" width={'85%'} />
        </div>
      </div>
    </div>
  );
}
