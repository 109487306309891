import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import TextArea from 'components/UI/TextArea';
import React, { useContext, useState } from 'react';
import { Configuration, OpenAIApi } from 'openai';
import { collection, addDoc } from 'firebase/firestore';
import { db } from 'firebaseConfig';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { UserContext } from 'context/userContext';

const CreateContentPage = () => {
  const userCtx = useContext(UserContext);

  const [text, setText] = useState('');
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    setResult([]);
    const configuration = new Configuration({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });
    const openai = new OpenAIApi(configuration);

    const response = await openai.createCompletion({
      model: 'text-davinci-002',
      prompt: text,
      temperature: 0.7,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    });
    console.log(response.data);
    if (response.status === 200) {
      response.data.choices.forEach((choice) => {
        setResult((prev) => [...prev, choice.text]);
      });
    }

    setLoading(false);
  };

  const handleSave = async () => {
    setSaveLoading(true);

    if (result.length === 0 || text === '') {
      toast.error('Please enter text and generate ideas first.');
      return;
    } else {
      const docRef = await addDoc(collection(db, 'contents'), {
        text: text,
        result: result,
        userId: userCtx.id,
      });

      if (docRef.id) {
        toast.success('Saved successfully');
        setText('');
        setResult([]);
      }
      setSaveLoading(false);
    }
  };

  return (
    <div>
      <Toaster />

      <div className="flex gap-2 items-center text-lg font-medium justify-between w-full">
        <div>Create Content Idea</div>
      </div>

      <div>
        <div className="flex gap-2 items-center text-sm text-gray-500  my-2">
          <Link to="/content-ideas">{'<'} Back to content ideas page</Link>
        </div>
      </div>

      <div className="flex flex-col gap-5 mt-5">
        <div>
          <div className="text-sm">Enter your keywords or sentence</div>
          <TextArea
            placeholder="Enter your suggest, create or what you want. Example: Write a text about candy"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />

          <div className="mt-4 w-full flex justify-end">
            <Button isLoading={loading} onClick={handleClick}>
              Give me ideas
            </Button>
          </div>
        </div>

        <div>
          <div className="text-sm">Enter your keywords or sentence</div>
          <TextArea
            rows={15}
            disabled
            className="disabled:bg-white resize-none"
            value={result.join('\n')}
          />
        </div>
        <div className="mt-4 w-full flex justify-end">
          <Button
            isLoading={saveLoading}
            className="bg-green-600 hover:bg-green-700 focus:ring-green-500"
            onClick={handleSave}
          >
            Save to my content ideas
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateContentPage;
