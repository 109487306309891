import {
  CheckCircleIcon,
  CurrencyDollarIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useState } from 'react';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from 'firebaseConfig';
import { UserContext } from 'context/userContext';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';

const PersonalPlanPage = () => {
  const [loadingCancel, setLoadingCancel] = useState(false);

  const includedFeatures = {
    free: [
      'Limited to 5 decisions',
      'Limited to 5 order',
      'Limited to 5 content ideas',
    ],
    pro: [
      'Unlimited decisions',
      'Unlimited order',
      'Unlimited content ideas',
      'Priority support',
    ],
  };

  const userCtx = useContext(UserContext);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [plan, setPlan] = useState('free');

  const getInfo = async () => {
    setLoadingInfo(true);
    const docSnap = await getDoc(doc(db, 'users', userCtx.id));

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      // setIncomingData(docSnap.data());
      if (docSnap.data().plan) {
        setPlan(docSnap.data().plan);
      }
    }
    setLoadingInfo(false);
  };

  useEffect(() => {
    getInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="bg-gray-100">
        {loadingInfo ? (
          <Loading />
        ) : (
          <div className="pb-16  sm:pb-20 lg:pb-28">
            <div className="flex gap-2 items-center text-lg font-medium mb-6">
              <RocketLaunchIcon className="h-6 w-6" />
              <div>Personal Plan</div>
            </div>
            <div className="relative">
              <div className="absolute inset-0 h-1/2 bg-gray-100" />
              <div className="relative max-w-7xl">
                <div className="max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none">
                  <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                    <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">
                      Lifetime Membership
                    </h3>
                    <p className="mt-6 text-base text-gray-500">
                      When you purchase a lifetime membership, you will get
                      unlimited access to all features of the app. You can see
                      your plan details below.
                    </p>
                    <div className="mt-8">
                      <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">
                          What's included
                        </h4>
                        <div className="flex-1 border-t-2 border-gray-200" />
                      </div>
                      <ul className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                        {includedFeatures[plan].map((feature) => (
                          <li
                            key={feature}
                            className="flex items-start lg:col-span-1"
                          >
                            <div className="flex-shrink-0">
                              <CheckCircleIcon
                                className="h-5 w-5 text-green-400"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="ml-3 text-sm text-gray-700">
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="bg-gray-50 py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12">
                    <p className="text-lg font-medium leading-6 text-gray-900">
                      Pay once, own it forever
                    </p>
                    <div className="mt-4 flex items-center justify-center text-5xl font-bold tracking-tight text-gray-900">
                      <span>{plan === 'free' ? '0$' : '8$'}</span>
                      <span className="ml-3 text-xl font-medium tracking-normal text-gray-500">
                        USD
                      </span>
                    </div>

                    <div className="mt-6">
                      <div className="rounded-md shadow">
                        <button
                          disabled={plan === 'free'}
                          href="!#"
                          className="w-full flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-5 py-3 text-base font-medium text-white "
                        >
                          {plan === 'free'
                            ? 'Your Plan is Free'
                            : 'Your Plan is Pro'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalPlanPage;
