import GoogleIcon from 'assets/icons/google.svg';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { Link, useNavigate } from 'react-router-dom';
import Logo from 'assets/icons/logo.png';
import Dashboard from 'assets/images/dashboard.png';

import { useState } from 'react';

import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
} from 'firebase/auth';
import { auth, db } from 'firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';

export default function LoginPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    } else if (password.length < 6) {
      alert('Password must be at least 6 characters');
      return;
    } else if (!email) {
      alert('Email is required');
      return;
    } else if (!fullName) {
      alert('Full Name is required');
      return;
    } else {
      setLoading(true);
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const userRef = doc(db, 'users', auth.currentUser.uid);
          const docSnap = getDoc(userRef);

          if (docSnap.exists) {
            console.log('Document data:', docSnap.data());
          } else {
            setDoc(doc(db, 'users', auth.currentUser.uid), {
              displayName: fullName,
              email: auth.currentUser.email,
            });
          }
          navigate('/');
        })
        .catch((error) => {
          const errorMessage = error.message;
          alert(errorMessage);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.

        const userRef = doc(db, 'users', auth.currentUser.uid);
        const docSnap = getDoc(userRef);

        if (docSnap.exists) {
          console.log('Document data:', docSnap.data());
        } else {
          setDoc(doc(db, 'users', auth.currentUser.uid), {
            displayName: auth.currentUser.displayName,
            email: auth.currentUser.email,
          });
        }

        // add to firestore if not exists
      })
      .catch((error) => {
        // Handle Errors here.
        const errorMessage = error.message;
        alert(errorMessage);
        // ...
      });
  };

  return (
    <div className="grid lg:grid-cols-2 ">
      <div className="col-span-1 min-h-screen overflow-auto shadow-2xl">
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-10 w-auto"
              src={Logo}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Create your account
            </h2>
            <div className="text-center text-sm text-gray-700 mt-2">
              Already have an account?{' '}
              <Link
                to="/login"
                className="text-blue-500 font-semibold hover:underline"
              >
                Sign in
              </Link>
            </div>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4  sm:px-10">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="fullName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Name
                  </label>
                  <div className="mt-1">
                    <Input
                      onChange={(e) => setFullName(e.target.value)}
                      id="fullName"
                      type="text"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <Input
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      type="email"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <Input
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      type="password"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="confirmpassword"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <Input
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      id="confirmpassword"
                      type="password"
                    />
                  </div>
                </div>

                <div>
                  {/*Privacy policy selectbox */}
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="privacy"
                        name="privacy"
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                      <label
                        htmlFor="privacy"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        By signing up, you agree to Recatpaw's{' '}
                        <a
                          href="/privacy-policy"
                          target={'_blank'}
                          className="text-blue-500 font-semibold hover:underline"
                        >
                          Privacy Policy
                        </a>
                        <span className="sr-only">.</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div>
                  <Button isLoading={loading} type="submit" className="w-full">
                    Create account
                  </Button>
                </div>
              </form>

              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-2 text-gray-500">
                      Or continue with
                    </span>
                  </div>
                </div>

                <div className="mt-6 ">
                  <div onClick={() => handleGoogleLogin()}>
                    <div className="cursor-pointer flex gap-4 w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
                      <img src={GoogleIcon} alt="" width={22} />

                      <span>Sign in with Google</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-1 relative hidden lg:block">
        <div className="bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 w-full h-full flex justify-center items-center">
          <img src={Dashboard} alt="" width={'85%'} />
        </div>
      </div>
    </div>
  );
}
